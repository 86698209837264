import React, { useState, useRef, useEffect } from 'react';

function App() {
  const [formData, setFormData] = useState({
    tenantNames: '',
    tenantEmail: '',
    checkInDate: '',
    checkOutDate: '',
    rentalAmount: '',
    securityDeposit: '',
    percentage: 30,
    agreementDate: '',
  });

  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);

 useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const rect = canvas.getBoundingClientRect();
  
  // Set initial size
  canvas.width = rect.width;
  canvas.height = rect.height;
  
  // Set drawing style
  ctx.strokeStyle = '#000';
  ctx.lineWidth = 2;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'rentalAmount' || name === 'percentage') {
      const rental = name === 'rentalAmount' ? parseFloat(value) || 0 : parseFloat(formData.rentalAmount) || 0;
      const percent = name === 'percentage' ? parseFloat(value) || 0 : parseFloat(formData.percentage) || 0;
      const calculatedDeposit = ((rental * percent) / 100).toFixed(2);
      setFormData((prev) => ({ ...prev, securityDeposit: calculatedDeposit }));
    }
  };

  // Update these functions in your App.js
const startDrawing = (e) => {
  const canvas = canvasRef.current;
  const rect = canvas.getBoundingClientRect();
  
  // Get the correct coordinates
  const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
  const y = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;
  
  const scaleX = canvas.width / rect.width;
  const scaleY = canvas.height / rect.height;
  
  setIsDrawing(true);
  setLastX(x * scaleX);
  setLastY(y * scaleY);
};

const draw = (e) => {
  if (!isDrawing) return;
  e.preventDefault();

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const rect = canvas.getBoundingClientRect();

  const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
  const y = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;

  const scaleX = canvas.width / rect.width;
  const scaleY = canvas.height / rect.height;

  const currentX = x * scaleX;
  const currentY = y * scaleY;

  ctx.beginPath();
  ctx.moveTo(lastX, lastY);
  ctx.lineTo(currentX, currentY);
  ctx.stroke();

  setLastX(currentX);
  setLastY(currentY);
};

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const handleClearSignature = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const signatureData = canvasRef.current.toDataURL('image/png');

    try {
      const response = await fetch('/agreement/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ formData, signature: signatureData }),
      });

      if (response.ok) {
        alert('Emails sent successfully!');
        setFormData({
          tenantNames: '',
          tenantEmail: '',
          checkInDate: '',
          checkOutDate: '',
          rentalAmount: '',
          securityDeposit: '',
          percentage: 30,
          agreementDate: '',
        });
        handleClearSignature();
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white p-8 shadow-lg rounded-lg w-full max-w-4xl">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Holiday Rentals Bargara</h1>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Property Address */}
          <div>
            <h2 className="text-xl font-semibold mb-3">Property Address</h2>
            <p className="text-gray-600 border p-3 rounded bg-gray-50">
              Unit 2, 5 Whalley St, Bargara, QLD, Australia
            </p>
          </div>

          {/* Agreement Date */}
          <div>
            <h2 className="text-xl font-semibold mb-3">Agreement Details</h2>
            <div>
              <label className="block text-sm font-medium mb-1">Agreement Date</label>
              <input
                type="date"
                name="agreementDate"
                value={formData.agreementDate}
                onChange={handleChange}
                className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                required
              />
            </div>
          </div>

          {/* Tenant Info */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Tenant Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block mb-1 text-sm font-medium">Tenant Name(s)</label>
                <input
                  type="text"
                  name="tenantNames"
                  value={formData.tenantNames}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  placeholder="Enter Full Name(s)"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-sm font-medium">Tenant Email</label>
                <input
                  type="email"
                  name="tenantEmail"
                  value={formData.tenantEmail}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  placeholder="Enter Email Address"
                  required
                />
              </div>
            </div>
          </div>

          {/* Rental Period */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Rental Period</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block mb-1 text-sm font-medium">From Date</label>
                <input
                  type="date"
                  name="checkInDate"
                  value={formData.checkInDate}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-sm font-medium">To Date</label>
                <input
                  type="date"
                  name="checkOutDate"
                  value={formData.checkOutDate}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  required
                />
              </div>
            </div>
          </div>

          {/* Rental Costs */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Rental Costs</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block mb-1 text-sm font-medium">Rental Amount ($)</label>
                <input
                  type="number"
                  name="rentalAmount"
                  value={formData.rentalAmount}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  placeholder="Enter Amount"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-sm font-medium">Security Deposit (%)</label>
                <input
                  type="number"
                  name="percentage"
                  value={formData.percentage}
                  onChange={handleChange}
                  className="w-full border p-2 rounded focus:ring focus:ring-blue-300"
                  placeholder="30%"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-sm font-medium">Calculated Deposit ($)</label>
                <input
                  type="text"
                  name="securityDeposit"
                  value={formData.securityDeposit}
                  className="w-full border p-2 rounded bg-gray-50 text-gray-500"
                  readOnly
                />
              </div>
            </div>
          </div>

          {/* Terms and Conditions */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
            <div className="bg-gray-50 p-4 rounded border space-y-3 text-sm">
              <h3 className="font-semibold">1. Check-in and Check-out</h3>
              <p>• Check-in time is from 2:00 PM</p>
              <p>• Check-out time is by 10:00 AM</p>
              
              <h3 className="font-semibold mt-4">2. Property Care and Maintenance</h3>
              <p>• The property must be kept clean and tidy throughout the stay</p>
              <p>• All garbage must be disposed of in the designated bins provided</p>
              <p>• Dishes must be cleaned and no food left on counters</p>
              <p>• Furniture must not be moved without prior approval</p>
              
              <h3 className="font-semibold mt-4">3. Security Deposit</h3>
              <p>• The security deposit will be refunded within 7 days after check-out</p>
              <p>• Deductions may be made for damages, excessive cleaning, or rule violations</p>
              
              <h3 className="font-semibold mt-4">4. General Rules</h3>
              <p>• Smoking is strictly prohibited indoors</p>
              <p>• Excessive noise is not permitted</p>
              <p>• Maximum occupancy must not be exceeded</p>
              <p>• Pets are not allowed unless specifically approved</p>
              
              <h3 className="font-semibold mt-4">5. Cancellation Policy</h3>
              <p>• Cancellations must be made at least 14 days prior to check-in for a full refund</p>
              <p>• Late cancellations may result in forfeiture of payments</p>
            </div>
          </div>

          {/* Signature */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Tenant Signature</h2>
            <p className="text-sm text-gray-600 mb-4">
              By signing below, I acknowledge that I have read, understood, and agree to all terms and conditions stated in this rental agreement.
            </p>
            <div className="border rounded p-4">
              <canvas
                ref={canvasRef}
                className="w-full h-48 border rounded bg-white cursor-crosshair"
                style={{ 
                  touchAction: 'none',
                  width: '100%',
                  height: '200px'
                }}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={stopDrawing}
                onTouchCancel={stopDrawing}
              />
              <button
                type="button"
                onClick={handleClearSignature}
                className="mt-3 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Clear Signature
              </button>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition duration-300"
          >
            Submit Agreement
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;